// @ts-nocheck

import { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { post } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { Card, CardContent, Button, IconButton, Skeleton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Select, MenuItem, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
import { formatData } from "../../../../../Services/DataFormatService";
import CollectionReportView from '../../Debtordetail/CollectionReportView/CollectionReportView';
import TextDisplayM from '../../../../Helpers/TextDisplayM/TextDisplayM';
import { filterJson } from '../../../../../Constants/constants';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

const firstDropdownOptions = Object.entries(filterJson).map(([label, obj]) => ({
    label,
    value: obj.value,
}));

const secondDropdownOptions = [
    { label: "Is Equals", value: "is" },
    { label: "Is Not Equals", value: "is_not" },
    { label: "Include", value: "include" },
    { label: "Greater Than", value: "greater_than" },
    { label: "Less Than", value: "less_than" },
    { label: "Is Empty", value: "isEmpty" },
];

function CollectionBookReport() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'debtordetail';

    // data vars
    const [allData, setAllData] = useState([]);
    const [filters, setFilters] = useState([{ field1: "", field2: "is", field3: "" },]);
    const [selectedData, setSelectedData] = useState({});
    const [totalArrearAmt, setTotalArrearAmt] = useState(0);

    //ui vars
    const [loading, setLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [toggleViewDetails, setToggleViewDetails] = useState(false);

    //table vars
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [paginationFlag, setPaginationFlag] = useState(false);

    // Add a new filter row
    const addFilterRow = () => {
        setFilters([...filters, { field1: "", field2: "is", field3: "" }]);
    };

    // Remove a filter row
    const removeFilterRow = (index) => {
        const updatedFilters = filters.filter((_, i) => i !== index);
        setFilters(updatedFilters);
    };

    // Update a specific field in a filter row
    const handleFilterChange = (index, field, value) => {
        const updatedFilters = [...filters];
        updatedFilters[index][field] = value;

        if (field === "field2" && value === "isEmpty") {
            updatedFilters[index].field3 = true; // Automatically set field3 to true
        } else if (field === "field2" && updatedFilters[index].field3 === true) {
            updatedFilters[index].field3 = ""; // Reset field3 if it's not isEmpty
        }

        setFilters(updatedFilters);
    };

    const applyFilter = () => {
        setCurrentPage(0);
        setPageSize(25);
        submitFilter();
    };

    const submitFilter = () => {
        setLoading(true);

        post(moduleMain + '/filter/genesys', { filters: filters, page: currentPage, size: pageSize, isTable: true })
            .then(_res => {

                setAllData(_res);

                // set total count
                setTotalRowCount(_res.rows.count);
                setTotalArrearAmt(_res.breachAmountsSum);

                const rows: any = [];
                _res.rows.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.accountName, _record?.account_status,
                    // _record?.conv30Days,_record?.conv60Days, _record?.conv90Days, _record?.conv120Days, _record?.conv180Days, _record?.convMoreThan180Days,
                    _record?.msisdn, _record?.msisdn_cnt, _record?.paymentMethod, _record?.mtn_acc_status,
                    _record?.firstName, _record?.surName, _record?.birthDt, _record?.es_rank, _record?.ageDays, _record?.ls_rank,
                    _record?.mtn_status, _record?.date_entered
                    ]))
                });

                setTableData(rows);

                setLoading(false);
            })
            .catch(_err => {
                console.log(_err);
                setLoading(false); // hide loader
            })
    };

    const handleToggleViewLeads = (_data) => {
        setSelectedData(_data);
        setToggleViewDetails(!toggleViewDetails);
    }

    useEffect(() => {
        submitFilter();
    }, [currentPage, pageSize])


    let columns = [
        { name: "Account Number", options: { filterOptions: { fullWidth: true } } },
        { name: "Account Status", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 1-30", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 31-60", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 61-90", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 91-120", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 121-150", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 151-180", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days Greater Than 180", options: { filterOptions: { fullWidth: true } } },
        { name: "Msisdn", options: { filterOptions: { fullWidth: true } } },
        { name: "Msisdn Cnt", options: { filterOptions: { fullWidth: true } } },
        { name: "Payment_method", options: { filterOptions: { fullWidth: true } } },
        { name: "Mtn Acc Status", options: { filterOptions: { fullWidth: true } } },
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Sur Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Birth Date", options: { filterOptions: { fullWidth: true } } },
        { name: "ES Rank", options: { filterOptions: { fullWidth: true } } },
        { name: "Age Days", options: { filterOptions: { fullWidth: true } } },
        { name: "Ls Rank", options: { filterOptions: { fullWidth: true } } },
        { name: "MTN Status'n", options: { filterOptions: { fullWidth: true } } },
        { name: "Date Entered", options: { filterOptions: { fullWidth: true } } },

        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => { return { align: "right" } },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                <Tooltip title={t('common.view')}>
                                    <IconButton aria-label="delete" size="small"
                                        onClick={() => handleToggleViewLeads(allData.rows.rows[rowIndex])}
                                    >
                                        <FontAwesomeIcon className="edit-btn" icon='eye' />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    )
                        ;
                }
            }
        }
    ];

    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: true,
        filter: false,
        filterType: "checkbox",
        responsive: "scroll",
        resizableColumns: false,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 75, 100],
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        tableBodyHeight: '400px',
        selectableRowsHideCheckboxes: false,
        sort: false,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        },
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <>
            {/* Upper Section */}
            <div className="row">
                <div className="col-md-8">
                    <h2 className="mt-3">
                        View All Collection Report
                    </h2>
                </div>
            </div>

            <div className="row mt-5">
                <Card sx={{ p: 2, boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                            FILTER
                        </Typography>
                        {filters.map((filter, index) => (
                            <Box
                                className="mt-4"
                                key={index}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                    mb: 2
                                }}
                            >
                                {/* First Dropdown */}
                                <Select
                                    value={filter.field1}
                                    onChange={(e) => handleFilterChange(index, "field1", e.target.value)}
                                    displayEmpty
                                    fullWidth
                                    size="small"
                                >
                                    <MenuItem value="" disabled>
                                        Select Field
                                    </MenuItem>
                                    {firstDropdownOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {/* Second Dropdown */}
                                <Select
                                    value={filter.field2}
                                    onChange={(e) => handleFilterChange(index, "field2", e.target.value)}
                                    fullWidth
                                    size="small"
                                >
                                    {secondDropdownOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {/* Text Input or Read-Only Field */}
                                {filter.field2 === "isEmpty" ? (
                                    <TextField
                                        value="true"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        fullWidth
                                        size="small"
                                    />
                                ) : (
                                    <TextField
                                        value={filter.field3}
                                        onChange={(e) => handleFilterChange(index, "field3", e.target.value)}
                                        placeholder="Enter Value"
                                        fullWidth
                                        size="small"
                                    />
                                )}

                                {/* Remove Row Button */}
                                <IconButton onClick={() => removeFilterRow(index)} color="error">
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Box>
                        ))}

                        {/* Add Row Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircleOutline />}
                            onClick={addFilterRow}
                        >
                            Add Row
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            className='ms-3'
                            onClick={applyFilter}
                        >
                            Apply Filter
                        </Button>
                    </CardContent>
                </Card>
            </div>

            <div className="row mt-5">
                {/* Total Unique Accounts Count */}
                <div className="col-md-6">
                    <TextDisplayM label={'Total Accounts'}
                        data={parseFloat(totalRowCount || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                        isLoading={loading} />
                </div>

                {/* Total Account Balance */}
                <div className="col-md-6">
                    <TextDisplayM
                        data={parseFloat(totalArrearAmt || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                        label={'Total Arrears Amount (ZAR)'}
                        isLoading={loading}
                        background={'custom-secondary'} />
                </div>
            </div>

            <div className="mt-5">
                <Paper elevation={12}>
                    <MUIDataTable
                        title={t('common.allRecords')}
                        data={tableData}
                        columns={columns}
                        // @ts-ignore
                        options={options}
                    />
                </Paper>
            </div>



            {toggleViewDetails &&
                <div>
                    <CollectionReportView data={selectedData} />
                </div>
            }

            {/* Alerts */}
            <AlertM ref={notifications} />
        </>
    );
}

export default CollectionBookReport;
