// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { Skeleton } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function GenesysDiallerFileHistory() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'filehistory';

    // data vars
    const [allData, setAllData] = React.useState([]);

    let columns = [
        { name: "File Name" },
        { name: "No. of Records" },
        { name: "Exported At" }
    ];
    const [tableData, setTableData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);

    // table ui controls
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader
        getAll(moduleMain, currentPage, pageSize)
            .then((_res: any) => {
                setAllData(_res.rows)
                setTotalRowCount(_res.count);
                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.fileName, _record?.noOfRecords, _record?.createdAt]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch()
    }, [])

    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        // filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: false,
        sort: false,
        onChangePage: (currentPage) => { setCurrentPage(currentPage); },
        onChangeRowsPerPage: (numberOfRows) => { setPageSize(numberOfRows); },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);
            // console.dir(state);
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                Genesys Dialler File History
                            </h2>
                        </div>
                    </div>

                    <div className="mt-5">
                        <Paper elevation={12}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>

                </div>
            </Box>
        </Box>
    );
}

export default GenesysDiallerFileHistory;
