import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    register: {
                        tagline: 'Register with email',
                        haveAccount: 'Already have an account?',
                        createSuccess: 'Account Created',
                        creationMailSent: 'An activation email has been sent to you,'
                    },
                    login: {
                        tagline: 'Enter your email and password to login',
                        dontHaveAccount: 'Don\'t have an account?',
                        loginFailed: 'Login Failed',
                        invalidCredentials: 'Invalid login credentials'
                    },
                    form: {
                        email: 'Email',
                        password: 'Password'
                    },
                    common: {
                        import: 'Import',
                        back: 'Back',
                        confirm: 'Confirm',
                        add: 'Add',
                        edit: 'Update',
                        delete: 'Delete',
                        cancel: 'Cancel',
                        view: 'View',
                        success: 'Success',
                        search: 'Search',
                        allRecords: 'All Records',
                        recordDetails: 'Record Details',
                        tryAgain: 'Please try again later',
                        somethingWentWrong: 'Something went wrong',
                        createSuccess: 'Record created successfully',
                        editSuccess: 'Record updated successfully',
                        deleteSuccess: 'Record deleted successfully',
                        genericSuccess: 'Operation completed successfully',
                        copiedSuccess: 'Data Copied Successfully',
                        export: 'Export',
                        editText: "Edit",
                    },
                    module: {
                        paymentlogs: 'PaymentLogs',
                        strategy: 'Strategies',
                        user: 'Users',
                        register: 'Register',
                        login: 'Login',
                        role: 'Roles',
                        bookfield: 'Book Fields',
                        accountstatus: 'Account Statuses',
                        location: 'Locations',
                        dispositionstatus: 'Disposition Statuses',
                        agentaccountstatus: 'Agent Account Statues',
                        reportingteam: 'Reporting Teams',
                        supplier: 'Suppliers',
                        typeofdebt: 'Type of Debts',
                        team: 'Teams',
                        campaign: 'Campaigns',
                        bookdetail: 'Book Details',
                        account: 'Accounts',
                        debtordetail: 'Debtor Details',
                        claimdetail: 'Claim Details',
                        accountnotepad: 'Accounts Notepad',
                        bookdebtors: 'Debtors Books',
                        debtoragent: 'Debtor Agents',
                        smstemplate: 'SMS Templates',
                        ptp: 'PTPs',
                        ptpmethod: 'PTP Methods',
                        bankdetail: 'Bank Details',
                        secondarycontactdetail: 'Secondary Contact Details',
                        contacttype: 'Contact Types',
                        partybankinfo: 'Party Bank Info',
                        assignedLead: 'Assigned Leads',
                        edc: 'EDC',
                        leadReport: 'Lead Reports',
                        genesysoutboundlist: 'Genesys Outbound Call List',
                        collectionextract: 'Collection Extract',
                        wrapupCode: 'Wrap up Codes',
                        edcConfiguration: 'EDC Configuration'
                        // add new module here %TEMPLATE_MODULE_NAME%
                    },
                    role: {
                        title: 'Title',
                    },
                    edcConfiguration: {
                        name: "Name",
                        percentage: "Percentage %"
                    },
                    genesysoutboundlist: {
                        accountNumber: 'Account Number',
                        idNumber: 'ID Number',
                        debitOrderDate: 'Debit Order',
                        arrearAmount: 'Arrear Amount',
                        customerName: 'Customer Name',
                        totalAccount: 'Total Accounts',
                        totalNumberOfArrears: 'Total Number Of Arrears',
                        bestDay: 'Best Day',
                        payDateRangeStart: 'Pay Date Range Start',
                        payDateRangeEnd: 'Pay Date Range End',
                        esRank: 'ES Rank',
                        predAvailableInstallment: 'Pred Available Installments',
                        ageDays: 'Age Days',
                        lsRank: 'IS Rank',
                        xCtel1: 'C Tel 1',
                        xCtel2: 'C Tel 2',
                        xHtel1: 'H Tel 1',
                        xWtel1: 'W Tel 1',
                        contactability: 'Contactability',
                        mtnStatus: 'MTN Status',
                        contactDialing: 'Contact Dialing',
                        timezone: 'Timezone',
                        dateEntered: 'Date Entered',
                        fileType: 'File Type',
                    },
                    wrapupCode: {
                        accountNumber: 'Account Number',
                        idNumber: 'ID Number',
                        debitOrderDate: 'Debit Order',
                        arrearAmount: 'Arrear Amount',
                        customerName: 'Customer Name',
                        totalAccount: 'Total Accounts',
                        totalNumberOfArrears: 'Total Number Of Arrears',
                        bestDay: 'Best Day',
                        payDateRangeStart: 'Pay Date Range Start',
                        payDateRangeEnd: 'Pay Date Range End',
                        esRank: 'ES Rank',
                        predAvailableInstallment: 'Pred Available Installments',
                        ageDays: 'Age Days',
                        lsRank: 'IS Rank',
                        xCtel1: 'C Tel 1',
                        xCtel2: 'C Tel 2',
                        xHtel1: 'H Tel 1',
                        xWtel1: 'W Tel 1',
                        contactability: 'Contactability',
                        mtnStatus: 'MTN Status',
                        contactDialing: 'Contact Dialing',
                        timezone: 'Timezone',
                        dateEntered: 'Date Entered',
                        fileType: 'File Type',
                        contactCallable: 'Contact Callable',
                        contactableByVoice: 'Contactable By Voice',
                        contactableBySms: 'Contactable By Sms',
                        contactableByEmail: 'Contactable By Email',
                        callRecordLastAttempt_X_CTEL_1: 'Call Record Last Attempt X_CTEL_1',
                        callRecordLastResult_X_CTEL_1: 'Call Record Last Result X_CTEL_1',
                        callRecordLastAgentWrapup_X_CTEL_1: 'Call Record Last Agent Wrapup X_CTEL_1',
                        smsLastAttempt_X_CTEL_1: 'Sms Last Attempt X_CTEL_1',
                        smsLastResult_X_CTEL_1: 'Sms Last Result X_CTEL_1',
                        callable_X_CTEL_1: 'Callable X_CTEL_1',
                        contactableByVoice_X_CTEL_1: 'Contactable By Voice X_CTEL_1',
                        contactableBySms_X_CTEL_1: 'Contactable By Sms X_CTEL_1',
                        callRecordLastAttempt_X_HTEL_1: 'Call Record Last Attempt X_HTEL_1',
                        callRecordLastResult_X_HTEL_1: 'Call Record Last Result X_HTEL_1',
                        callRecordLastAgentWrapup_X_HTEL_1: 'Call Record Last Agent Wrapup X_HTEL_1',
                        smsLastAttempt_X_HTEL_1: 'Sms Last Attempt X_HTEL_1',
                        smsLastResult_X_HTEL_1: 'Sms Last Result X_HTEL_1',
                        callable_X_HTEL_1: 'Callable X_HTEL_1',
                        contactableByVoice_X_HTEL_1: 'Contactable By Voice X_HTEL_1',
                        contactableBySms_X_HTEL_1: 'Contactable By Sms X_HTEL_1',
                        callRecordLastAttempt_X_WTEL_1: 'Call Record Last Attempt X_WTEL_1',
                        callRecordLastResult_X_WTEL_1: 'Call Record Last Result X_WTEL_1',
                        callRecordLastAgentWrapup_X_WTEL_1: 'Call Record Last Agent Wrapup X_WTEL_1',
                        smsLastAttempt_X_WTEL_1: 'Sms Last Attempt X_WTEL_1',
                        smsLastResult_X_WTEL_1: 'Sms Last Result X_WTEL_1',
                        callable_X_WTEL_1: 'Callable X_WTEL_1',
                        contactableByVoice_X_WTEL_1: 'Contactable By Voice X_WTEL_1',
                        contactableBySms_X_WTEL_1: 'Contactable By Sms X_WTEL_1',
                    },
                    bookfield: {
                        title: 'Title',
                        displayName: 'Display Name',
                        isMandatory: 'Is Mandatory',
                        addSystemField: 'Add System Field'
                    },
                    accountstatus: {
                        name: 'Status',
                    },
                    edc: {
                        fullName: 'Full Name',
                        name: 'Abbreviated Name',
                        edcId: 'EDC ID',
                        remotePath: 'Remote Path',
                        controlAccountNumber: 'Control Account Number',
                    },
                    location: {
                        location: 'Location',
                        sipServerIp: 'SIP Server IP',
                        ussdRef: 'USSD Ref',
                        smsProvider: 'SMS Provider',
                        smsUser: 'SMS User',
                        smsPassword: 'SMS Password',
                        smsFrom: 'SMS From',
                        dialPrefix: 'Dial Prefix',
                        logo: 'Logo',
                        timezone: 'Timezone',
                        monthEndDay: 'Month End Day',
                    },
                    dispositionstatus: {
                        name: 'Status',
                    },
                    user: {
                        firstName: 'First Name',
                        middleName: 'Middle Name',
                        lastName: 'Last Name',
                        email: 'Email',
                        contact: 'Contact',
                        roleId: 'Role',
                        isActive: 'Is Active',
                        isMicrosoftLinked: 'Is Microsoft Linked',
                        isGoogleLinked: 'Is Google Linked',
                        password: 'Password',
                        token: 'Token',
                        lastLogin: 'Last Login',
                        locationId: 'Location',
                        teamId: 'Team',
                        reportingTeamId: 'Reporting Team',
                        legalAgent: 'Legal Agent',
                        idNumber: 'Id',
                        collectorId: 'Collector',
                        phoneUsername: 'Phone',
                        phonePassword: 'Phone',
                        username: 'Username',
                    },
                    agentaccountstatus: {
                        name: 'Status',
                    },
                    reportingteam: {
                        name: 'Team',
                    },
                    supplier: {
                        name: 'Supplier',
                    },
                    typeofdebt: {
                        name: 'Type of Debt',
                    },
                    team: {
                        name: 'Name',
                        locationId: 'Location',
                        dialPrefix: 'Dial Prefix',
                    },
                    campaign: {
                        strategy: 'Strategies',
                        name: 'Name',
                        locationId: 'Location',
                        dateStart: 'Start Date',
                        dateExpire: 'Expire Date',
                        isActive: 'Active',
                        supplierId: 'Supplier',
                        showLinkedAccounts: 'Linked Accounts',
                        collectionTargetPercentage: 'Target Percentage',
                        typeOfDebtId: 'Type of Debt',
                        transactionsApi: 'Transaction API',
                        paymentsApi: 'Payment API',
                        minPayment: 'Min Payment',
                        notes: 'Notes',
                    },
                    bookdetail: {
                        bookReference: 'Book Refrence',
                        date: 'Date',
                        filePath: 'File Path',
                        supplierId: 'Supplier',
                        recordCount: 'Records Count',
                        campaignId: 'Campaign',
                    },
                    account: {
                        statusId: 'Status',
                        debtorId: 'Debtor',
                        agentAccountStatusId: 'Account Status',
                    },
                    debtordetail: {
                        appDate: 'Application Date',
                        dlrCode: 'Dialer Code',
                        source: 'Source',
                        finalDecision: 'Final Decision',
                        appNumber: 'Application Number',
                        orderNumber: 'Order Number',
                        caseNumber: 'Case Number',
                        prospectId: 'Prospect ID',
                        appSeq: 'Application Sequence',
                        idType: 'ID Type',
                        firstName: 'First Name',
                        surName: 'Surname',
                        accLines: 'Account Lines',
                        oldestContract: 'Oldest Contract',
                        churned: 'Churned',
                        terminationDate: 'Termination Date',
                        tblDt: 'Tbl Date',
                        accountName: 'Account Name',
                        availCreditAmt: 'Available Credit Amount',
                        brcChannel: 'BRC Channel',
                        brcOwnhership: 'BRC Ownership',
                        legalStatus: 'Legal Status',
                        migrationFlag: 'Migration Flag',
                        xMtnAccountLock: 'X MTN Account Lock',
                        debtCollecFlg: 'Debt Collection Flag',
                        billPaymentMethod: 'Bill Payment Method',
                        billStatusCd: 'Bill Status Cd',
                        bankName: 'Bank Name',
                        bankAccountType: 'Bank Account Type',
                        curcyCd: 'Currency Cd',
                        classificationNm: 'Classification Nm',
                        classificationDescr: 'Classification Description',
                        activeDt: 'Active Date',
                        msisdn: 'MSISDN',
                        serviceTypeNm: 'Service Type Nm',
                        serviceStatusNm: 'Service Status Nm',
                        contractStartDt: 'Contract Start Dt',
                        contractEndDt: 'Contract End Dt',
                        accountBalance: 'Account Balance',
                        arrearFlg: 'Arrear Flag',
                        balanceDate: 'Balance Date',
                        ageDays: 'Age Days',
                        openInv: 'Open Inv',
                        b_0_30: 'Between 0 to 30',
                        b_30_60: 'Between 30 to 60',
                        b_60_90: 'Between 60 to 90',
                        b_90_120: 'Between 90 to 120',
                        b_120_150: 'Between 120 to 150',
                        b_150_180: 'Between 150 to 180',
                        b_plus_180: 'Plus 180',
                        treatmentBreachAmnt: 'Treatment Breach Amount',
                        currTreatmentStatus: 'Current Treatment Status',
                        trtmntStsCd: 'Treatment Status CD',
                        trtmntStartDt: 'Treatment Start Date',
                        resolutionDueDate: 'Resolution Due Date',
                        trtmntCloseDt: 'Treatment Close Date',
                        trtmntQueryTxt: 'Treatment Query Text',
                        trtmntReslnTxt: 'Treatment Resolution Text',
                        currTreatmentLvl: 'Current Treatment Level',
                        nxtTreatmentLvl: 'Next Treatment Level',
                        ptpTotal: 'PTP Total',
                        ptpStsCd: 'PTP Status CD',
                        ptpSartDt: 'PTP Sart Date',
                        ptpDueDt: 'PTP Due Date',
                        ptpQueryTxt: 'PTP Query Text',
                        ptpReslnTxt: 'PTP Resolution Text',
                        ptpResolutionId: 'PTP Resolution ID',
                        ptpInstallmentTtl: 'PTP Installment TTL',
                        ptpInstallmentAmnt: 'PTP Installment Amount',
                        customerType: 'Customer Type',
                        invoiceAmt: 'Invoice Amout',
                        insertDt: 'Insert Date',
                        lastPaymentAmt: 'Last Payment Amount',
                        lastPaymentDt: 'Last Payment Date',
                        customerNodeStatusNm: 'Customer Node Status NM',
                        category: 'Category',
                        idPassportNumber: 'ID Passport Number',
                        birthDt: 'Birth Date',
                        balanceCategory: 'Balance Category',
                        mob: 'Mob',
                        mobCat: 'Mob Cat',
                        arrearPlus180Cat: 'Arrear Plus Cat',
                        exception1: 'Exception 1',
                        exception2: 'Exception 2',
                        exception3: 'Exception 3',
                        exception4: 'Exception 4',
                        exception5: 'Exception 5',
                        openBillQuery: 'Open Bill Query',
                        openDispute: 'Open Dispute',
                        simOnlyFlag: 'SIM Only Flag',
                        collectabilityScore: 'Collectability Score',
                        affordabilityBands: 'Affordability Bands',
                        collectionsPriority: 'Collections Priority',
                        numberOfMsisdn: 'Number Of MSISDN',
                        lastExclusionDate: 'Last Exclusion Date',
                        numberOfExclusions: 'Number Of Exclusions',
                        companyName: 'Company Name',
                        surname: 'Surname',
                        idNumber: 'Id Number',
                        dateOfBirth: 'Dateofbirth',
                        gender: 'Gender',
                        martialStatus: 'Martial Status',
                        jobTitle: 'Job Title',
                        income: 'Income',
                        email: 'Email',
                        phoneHome: 'Phone Home',
                        phoneWork: 'Phone Work',
                        phoneCell: 'Phone Cell',
                        employer: 'Employer',
                        address1: 'Address 1',
                        address2: 'Address 2',
                        address3: 'Address 3',
                        city: 'City',
                        province: 'Province',
                        country: 'Country',
                        dispositionStatusId: 'Disposition Status',
                        accountNumber: 'Account Number',
                        description: 'Description',
                        age: 'Age',
                        arrears: 'Arrears',
                        penaltyFee: 'Penalty Fee',
                        totalArrears: 'Total Arrears',
                        balance: 'Balance',
                        lastPaymentDate: 'Last Payment Date',
                        capital: 'Capital',
                        agreementPeriod: 'Agreement Period',
                        typeOfDebtId: 'Type of Debt',
                        accountStatusId: 'Account Status',
                        exclusionReason: 'Exclusion Reason',
                        startWorking: 'Start Working',
                        getAccount: 'Get Account',
                        billAccNo: 'Bill Account Number.',
                        customerAccountNum: 'Customer Account Number',
                        contactPerson: 'Contact Person',
                        email1: 'Email 1',
                        email2: 'Email 2',
                        email3: 'Email 3',
                        email4: 'Email 4',
                        hTel: 'Email 1',
                        wTel: 'Email 2',
                        cTel3: 'Email 3',
                        cTel4: 'Email 4',
                    },
                    claimdetail: {
                        arrearsToCollect: 'Arrears to Collect',
                        balance: 'Balance',
                        lastPaymentDate: 'Last Payment Date',
                        lastPaymentAmount: 'Last Payment Amount',
                        statusDescription: 'Status Description',
                        contractId: 'Contract',
                        tariff: 'Tariff',
                        processTypeId: 'Process Type',
                        bankName: 'Bank Name',
                        lastFourDigitsAccountNumber: 'Account Ending With',
                        contractEndDate: 'Contract End Date',
                        debtorId: 'Debtor',
                    },
                    accountnotepad: {
                        notes: 'Notes',
                        debtorId: 'Debtor',
                        agentId: 'Agent',
                        event: 'Event',
                    },
                    bookdebtors: {
                        bookId: 'Book',
                        debtorId: 'Debtor',
                    },
                    debtoragent: {
                        userId: 'Agent',
                        debtorId: 'Debtor',
                    },
                    smstemplate: {
                        subject: 'Title',
                        body: 'Body',
                    },
                    ptp: {
                        debtorId: 'Debtor',
                        ptpDate: 'Date',
                        amount: 'Amount',
                        ptpMethodId: 'Method',
                        isRecurring: 'Recurring',
                        paymentInterval: 'Payment Interval (Weeks)',
                        numberOfPayments: 'Number of Payments',
                        bankDetailsId: 'Bank',
                        isHonored: 'Honored',
                    },
                    ptpmethod: {
                        name: 'Name',
                    },
                    bankdetail: {
                        accountHolder: 'Account Holder',
                        bank: 'Bank ',
                        accountNumber: 'Account Number',
                        branchCode: 'Branch Code',
                        typeOfAccount: 'Type of Account',
                    },
                    secondarycontactdetail: {
                        contactTypeId: 'Contact Type',
                        detail: 'Detail',
                        debtorId: 'Debtor',
                    },
                    contacttype: {
                        title: 'Title',
                    },
                    partybankinfo: {
                        partyId: 'Party',
                        bankInfoId: 'Bank',
                    },
                    edcScoreCard: {
                        yieldRateWeight: 'Yield Rate Weight',
                        calculate: 'Calculate',
                        performaceCalculator: 'Performance Calculator',
                        recoveryRate: 'Recovery Rate',
                        writtenOffValue: 'Written Off value',
                        fraud: 'Fraud',
                        deceased: 'Deceased',
                        debtReview: 'Debt Review',
                        prescribed: 'Prescribed',
                        sold: 'Sold',
                        calculateScore: 'Calculate Score',
                        noOfAccountEdc: 'Account By Edc',
                        noOfAccountBook: 'Account By Book',
                        prescribedAccounts: 'Prescribed Accounts',
                        judgement: 'Judgement',
                        summons: 'Summons',
                        deviceRecovery: 'No. of Device Recovery',
                        msisdn: 'No. of MSISDN',
                        missdnSimOnly: 'No. of MSISDN Sim Only',
                        noOfEscalation: 'No. of escalation/repeated serive in a month',
                        deliveryOnTime: '% delivery on time',
                        accountMatched: 'Enriched Accounts',
                        legalAccount: 'Total Handovers',
                        rehabilitations: '% Rehabilitations',
                        ticketResolved: 'Ticket Resolved within 24h',
                        tikcetReceived: 'Ticket Received(Month)',
                        escalation: '% Escalation',
                        mtnMatrixUpdated:'MTN Matrix updated',
                        mtnMatrixSaved:'MTN Matrix saved',
                        monthlyEdcReportToMtn:'Monthly EDC reports sent to MTN',
                        monthlyEdcReportToGs:'Monthly EDC reports saved on Globalscape',
                        weeklyPay:'Weekly pay',
                        monthlyBase: 'Monthly base',
                        qaScore: '% QA Score',
                        specialProject: '% Project Delivery'
                    },
                    strategy: {
                        name: 'Name',
                        filters: 'Filters',
                        teams: 'Teams',
                        campaign: 'Campaign',
                        priority: 'Priority',
                        totalAccounts: 'Total Accounts'
                    }// add new module screen items here %TEMPLATE_MODULE_SCREEN_ITEMS%
                }
            },
            de: {
                translation: {
                    description: {
                        part1: 'Ändere <1>src/App.js</1> und speichere um neu zu laden.',
                        part2: 'Lerne React'
                    }
                }
            }
        }
    });

export default i18n;
