export const filterJson =  {
    "Account Name": {
        "value": "accountName"
    },
    "Account Status": {
        "value": "account_status"
    },
    "Customer Legal Status": {
        "value": "legalStatus"
    },
    "Last Msisdn Status Date": {
        "value": "last_msisdn_status_date"
    },
    "Account Last Status Dt": {
        "value": "account_last_status_dt"
    },
    "Account Last Balance": {
        "value": "account_last_balance"
    },
    "Account Previous Balance": {
        "value": "account_previous_balance"
    },
    "Last Payment Date": {
        "value": "lastPaymentDt"
    },
    "Last Invoice Date": {
        "value": "last_invoice_date"
    },
    "Last Invoice Amount": {
        "value": "last_invoice_amount"
    },
    "Payment Due Date": {
        "value": "payment_due_date"
    },
    "Balance Date ": {
        "value": "balanceDate"
    },
    "Days 1-30": {
        "value": "days_1_30"
    },
    "Days 31-60": {
        "value": "days_31_60"
    },
    "Days 61-90": {
        "value": "days_61_90"
    },
    "Days 91-120": {
        "value": "days_91_120"
    },
    "Days 121-150": {
        "value": "days_121_150"
    },
    "Days 151-180": {
        "value": "days_151_180"
    },
    "Days Greater Than 180": {
        "value": "days_greater_than_180"
    },
    "Msisdn": {
        "value": "msisdn"
    },
    "Msisdn Cnt": {
        "value": "msisdn_cnt"
    },
    "Msisdn Status": {
        "value": "msisdn_status"
    },
    "Sim Only Flag": {
        "value": "sim_only_flag"
    },
    "Active Date": {
        "value": "activeDt"
    },
    "Package Name": {
        "value": "package_name"
    },
    "Last Amount Paid": {
        "value": "lastPaymentAmt"
    },
    "Price Plan Group": {
        "value": "price_plan_group"
    },
    "Contract Start Date": {
        "value": "contractStartDt"
    },
    "Balance Date": {
        "value": "balanceDate"
    },
    "Contract End Date": {
        "value": "contractEndDt"
    },
    "Out Of Contract Flag": {
        "value": "out_of_contract_flag"
    },
    "Payment Method": {
        "value": "paymentMethod"
    },
    "Payment Flag": {
        "value": "payment_flag"
    },
    "Account Credit": {
        "value": "account_credit"
    },
    "Customer Category": {
        "value": "customer_category"
    },
    "Customer Account Number": {
        "value": "customerAccountNum"
    },
    "Cust Segment": {
        "value": "cust_segment"
    },
    "Acc Sub Status Code": {
        "value": "acc_sub_status_code"
    },
    "Mtn Acc Status": {
        "value": "mtn_acc_status"
    },
    "Mtn Acc Sub Status": {
        "value": "mtn_acc_sub_status"
    },
    "Termination Status": {
        "value": "termination_status"
    },
    "ID Type": {
        "value": "idType"
    },
    "ID Number": {
        "value": "id_number"
    },
    "Registration Number": {
        "value": "registraction_number"
    },
    "First Name": {
        "value": "firstName"
    },
    "Surname": {
        "value": "surName"
    },
    "Birth Dt": {
        "value": "birthDt"
    },
    "Fraud Status": {
        "value": "fraud_status"
    },
    "Fraud Message": {
        "value": "fraud_message"
    },
    "Risk Group": {
        "value": "risk_group"
    },
    "Preferred Contact Method": {
        "value": "preferred_contact_method"
    },
    "Authorizer Phone #": {
        "value": "authorizer_phone_no"
    },
    "Ctel-1": {
        "value": "cTel3"
    },
    "Htel-1": {
        "value": "hTel"
    },
    "Wtel-1": {
        "value": "wTel"
    },
    "Email ID": {
        "value": "email1"
    },
    "Physical Address": {
        "value": "physical_address"
    },
    "Home Address": {
        "value": "home_address"
    },
    "Billing Address": {
        "value": "billing_address"
    },
    "Work Address": {
        "value": "work_address"
    },
    "Salary payment day": {
        "value": "salary_payment_day"
    },
    "Payment Day": {
        "value": "payment_day"
    },
    "Last Query Step": {
        "value": "last_query_step"
    },
    "Last Query Date": {
        "value": "last_query_date"
    },
    "Write Off Amount": {
        "value": "write_off_amount"
    },
    "Last Query Open Date": {
        "value": "last_query_open_date"
    },
    "Last Query Close Date": {
        "value": "last_query_close_date"
    },
    "Device Type": {
        "value": "device_type"
    },
    "Last Active Use Date": {
        "value": "last_active_use_date"
    },
    "Last Active Use Type": {
        "value": "last_active_use_type"
    },
    "Last Recharge Date": {
        "value": "last_recharge_date"
    },
    "Last Recharge Type": {
        "value": "last_recharge_type"
    },
    "Last Usage status": {
        "value": "last_usage_status"
    },
    "MTN App Status": {
        "value": "mtn_app_status"
    },
    "WhatsApp Status Credit": {
        "value": "whatsApp_status"
    },
    "Payment Link": {
        "value": "payment_link"
    },
    "Balance Link": {
        "value": "balance_link"
    },
    "WhatsApp Link": {
        "value": "whatsApp_link"
    },
    "App Link": {
        "value": "app_link"
    },
    "Black Listing Status": {
        "value": "black_listing_status"
    },
    "Arrears Amount": {
        "value": "treatmentBreachAmnt"
    },
    "Last Rejected Date": {
        "value": "last_rejected_date"
    },
    "Number Of Payments": {
        "value": "number_of_payments"
    },
    "Store Name": {
        "value": "store_name"
    },
    "Brc Channel": {
        "value": "brc_channel"
    },
    "Debicheck/RMs Processing Flag": {
        "value": "debicheck_rms_processing_flag"
    },
    "Debicheck/RMs Suspension Flag": {
        "value": "debicheck_rms_suspension_flag"
    },
    "Debicheck/RMs Authorized Flag": {
        "value": "debicheck_rms_authorized_flag"
    },
    "collection_status": {
        "value": "collection_status"
    },
    "Collection Strategy Event Status": {
        "value": "collection_strategy_event_status"
    },
    "Collection Strategy": {
        "value": "collection_strategy"
    },
    "Debit Order Date": {
        "value": "debit_order_date"
    },
    "Customer Name": {
        "value": "customerName"
    },
    "Total Account": {
        "value": "tot_accnt"
    },
    "Total Number Arr ": {
        "value": "tot_num_arr"
    },
    "Bestday": {
        "value": "best_day"
    },
    "Paydate Range Start": {
        "value": "pay_date_ranges_start"
    },
    "Pay Date Range End": {
        "value": "pay_date_range_end"
    },
    "Pred Available Instalment": {
        "value": "pred_available_instalment"
    },
    "ES Rank": {
        "value": "es_rank"
    },
    "Age Days": {
        "value": "ageDays"
    },
    "LS Rank": {
        "value": "ls_rank"
    },
    "MTN Status": {
        "value": "mtn_status"
    },
    "Date Entered": {
        "value": "date_entered"
    },
    "Number of Open Service Requests": {
        "value": "no_of_open_service_requests"
    },
    "Number of Closed Service Requests": {
        "value": "no_of_closed_service_requests"
    },
    "SR Area": {
        "value": "sr_area"
    },
    "SR Status": {
        "value": "sr_status"
    },
    "SR Subarea": {
        "value": "sr_subarea"
    },
    "General 1": {
        "value": "general_1"
    },
    "General 2": {
        "value": "general_2"
    },
    "General 3": {
        "value": "general_3"
    },
    "General 4": {
        "value": "general_4"
    },
    "General 5": {
        "value": "general_5"
    },
    "General 6": {
        "value": "general_6"
    },
    "General 7": {
        "value": "general_7"
    },
    "General 8": {
        "value": "general_8"
    },
    "General 9": {
        "value": "general_9"
    },
    "General 10": {
        "value": "general_10"
    },
    "General 11": {
        "value": "general_11"
    },
    "General 12": {
        "value": "general_12"
    },
    "General 13": {
        "value": "general_13"
    },
    "General 14": {
        "value": "general_14"
    },
    "General 15": {
        "value": "general_15"
    },
    "General 16": {
        "value": "general_16"
    },
    "General 17": {
        "value": "general_17"
    },
    "General 18": {
        "value": "general_18"
    },
    "General 19": {
        "value": "general_19"
    },
    "General 20": {
        "value": "general_20"
    },
    "General 21": {
        "value": "general_21"
    },
    "General 22": {
        "value": "general_22"
    },
    "General 23": {
        "value": "general_23"
    },
    "General 24": {
        "value": "general_24"
    },
    "General 25": {
        "value": "general_25"
    },
    "General 26": {
        "value": "general_26"
    },
    "General 27": {
        "value": "general_27"
    },
    "General 28": {
        "value": "general_28"
    },
    "General 29": {
        "value": "general_29"
    },
    "General 30": {
        "value": "general_30"
    },
    "General 31": {
        "value": "general_31"
    },
    "General 32": {
        "value": "general_32"
    },
    "General 33": {
        "value": "general_33"
    },
    "General 34": {
        "value": "general_34"
    },
    "General 35": {
        "value": "general_35"
    },
    "General 36": {
        "value": "general_36"
    },
    "General 37": {
        "value": "general_37"
    },
    "General 38": {
        "value": "general_38"
    },
    "General 39": {
        "value": "general_39"
    },
    "General 40": {
        "value": "general_40"
    },
    "TU Employer 1": {
        "value": "tu_employer1"
    },
    "TU Employer 1 LastUpdate": {
        "value": "tu_employer1_lastUpdate"
    },
    "TU Employer 1 Occupation": {
        "value": "tu_employer1_occupation"
    },
    "TU WorkPhone 1 LastUpdate": {
        "value": "tu_workPhone1_lastUpdate"
    },
    "TU WorkPhone 1 Number": {
        "value": "tu_workPhone1"
    },
    "TU WorkPhone 2 LastUpdate": {
        "value": "tu_workPhone2_lastUpdate"
    },
    "TU WorkPhone 2 Number": {
        "value": "tu_WorkPhone2"
    },
    "TU WorkPhone 3 LastUpdate": {
        "value": "tu_workPhone3_lastUpdate"
    },
    "TU WorkPhone 3 Number": {
        "value": "tu_workPhone3"
    },
    "TU HomePhone 1 LastUpdate": {
        "value": "tu_homePhone1_lastUpdate"
    },
    "TU HomePhone 1 Number": {
        "value": "tu_homePhone1"
    },
    "TU HomePhone 2 LastUpdate": {
        "value": "tu_homePhone2_lastUpdate"
    },
    "TU HomePhone 2 Number": {
        "value": "tu_homePhone2"
    },
    "TU HomePhone 3 LastUpdate": {
        "value": "tu_homePhone3_lastUpdate"
    },
    "TU HomePhone 3 Number": {
        "value": "tu_homePhone3"
    },
    "TU CellNumber 1 LastUpdate": {
        "value": "tu_cellNumber1_lastUpdate"
    },
    "TU CellNumber 1 Number": {
        "value": "tu_cellNumber1_number"
    },
    "TU CellNumber 2 LastUpdate": {
        "value": "tu_cellNumber2_lastUpdate"
    },
    "TU CellNumber 2 Number": {
        "value": "tu_cellNumber2_number"
    },
    "TU CellNumber 3 LastUpdate": {
        "value": "tu_cellNumber3_lastUpdate"
    },
    "TU CellNumber 3 Number": {
        "value": "tu_cellNumber3_number"
    },
    "TU Email Address": {
        "value": "tu_email_address"
    },
    "TU Estimated Income": {
        "value": "tu_estimated_income"
    },
    "TU Collections Priority": {
        "value": "tu_collections_priority"
    },
    "TU Exclusion Reason": {
        "value": "tu_exclusion_reason"
    },
    "TU Score": {
        "value": "tu_score"
    },
    "TU Score Band": {
        "value": "tu_score_band"
    },
    "TU AFF Band": {
        "value": "tu_aff_band"
    },
    "TU Monthly Commitment": {
        "value": "tu_monthly_commitment"
    },
    "TU RangeStartDate 1": {
        "value": "tu_range_start_date1"
    },
    "TU RangeEndDate 1": {
        "value": "tu_range_end_date1"
    },
    "TU Maxpaydate 1": {
        "value": "tu_max_pay_date1"
    },
    "TU RangeStartDate 2": {
        "value": "tu_range_start_date2"
    },
    "TU RangeEndDate 2": {
        "value": "tu_range_end_date2"
    },
    "TU Maxpaydate 2": {
        "value": "tu_max_pay_date2"
    },
    "TU ConfidenceScore": {
        "value": "tu_confidence_score"
    },
    "TU ConfidenceLevel": {
        "value": "tu_confidence_level"
    },
    "Customer Entity Name": {
        "value": "customer_entity_name"
    },
    "Number of service requests": {
        "value": "number_of_service_requests"
    },
    "Number of open service requests": {
        "value": "number_of_open_service_requests"
    },
    "Number of closed service requests": {
        "value": "number_of_closed_service_requests"
    },
    "Sr Sub Area": {
        "value": "sr_sub_area"
    },
    "Ptp Payment Link": {
        "value": "ptp_payment_link"
    },
    "Package Code": {
        "value": "package_code"
    }
};