// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../Services/LoggerService";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { IconButton, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
import DeleteBookSingleView from '../DeleteBookSingleView/DeleteBookSingleView';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function DeleteBookMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'filehistory';

    // data vars
    const [allData, setAllData] = useState([]);
    const [bookData, setBookData] = useState(null);

    let columns = [
        { name: "File Name", options: { filterOptions: { fullWidth: true } } },
        { name: "No. of Records", options: { filterOptions: { fullWidth: true } } },
        { name: "Created Date", options: { filterOptions: { fullWidth: true } } },

        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => { return { align: "right" } },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                <Tooltip title={'View Book Details'}>
                                    <IconButton aria-label="delete" size="small" onClick={() => {
                                        onViewClick(dataIndex, rowIndex)
                                    }}>
                                        <FontAwesomeIcon className="edit-btn" icon='circle-info' />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    )
                        ;
                }
            }
        }
    ];

    const [tableData, setTableData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [view, setViewMode] = React.useState(false);

    // table ui controls
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [searchBtn, setSearchBtn] = useState(true);
    const [downloadBtn, setDownloadBtn] = useState(true);
    const [printBtn, setPrintBtn] = useState(true);
    const [viewColumnBtn, setViewColumnBtn] = useState(true);
    const [filterBtn, setFilterBtn] = useState(true);

    const resetView = () => {
        setViewMode(false);
    }
    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader
        getAll(moduleMain + '/close/book')
            .then((_res: any) => {

                setAllData(_res)

                const rows: any = [];
                _res.forEach((_record: any) => {
                    rows.push(formatData([_record?.fileName, _record?.noOfRecords, _record?.createdAt]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch()
    }, [])

    // event handlers
    const onViewClick = (_dataIndex: any, _rowIndex: any) => {
        setBookData(allData[_dataIndex]);
        setViewMode(true);
    }

    const resetEdit = (_state) => {
        setViewMode(false);
    }


    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        // tableBodyHeight: "500px",
        // tableBodyMaxHeight,
        selectableRowsHideCheckboxes: true,
        sort: true,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);
            // console.dir(state);
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                {!view &&

                    <div className="px-1 px-md-5">

                        {/* Upper Section */}
                        <div className="row">
                            <div className="col-md-10">
                                <h2 className="mt-3">
                                    Close Collection Book Records
                                </h2>
                            </div>
                        </div>

                        <div className="mt-5">
                            <Paper elevation={12}>
                                <MUIDataTable
                                    title={t('common.allRecords')}
                                    data={tableData}
                                    columns={columns}
                                    // @ts-ignore
                                    options={options}
                                />
                            </Paper>
                        </div>

                    </div>
                }
                {/* display edit modal */}
                {view &&
                    <div>
                        <DeleteBookSingleView refreshDataEvent={resetView}
                            resetEditState={resetEdit}
                            bookData={bookData}
                        />
                    </div>
                }


                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box>
    );
}

export default DeleteBookMain;
