// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { post, getAll } from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';

import { formatData } from "../../../../../Services/DataFormatService";
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, TextField, FormControlLabel, Typography, IconButton, Checkbox, FormHelperText, Skeleton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from '@mui/lab';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { filterJson } from '../../../../../Constants/constants';


const firstDropdownOptions = Object.entries(filterJson).map(([label, obj]) => ({
    label,
    value: obj.value,
}));

const secondDropdownOptions = [
    { label: "Is Equals", value: "is" },
    { label: "Is Not Equals", value: "is_not" },
    { label: "Include", value: "include" },
    { label: "Greater Than", value: "greater_than" },
    { label: "Less Than", value: "less_than" },
    { label: "Is Empty", value: "isEmpty" },
];

// @ts-ignore
function StrategyForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'strategy';

    // linked components API modules (endpoints)
    const moduleCampaigns = 'campaign';
    const moduleTeams = 'team';
    const moduleDebtorDetail = 'debtordetail';

    // translation
    const { t } = useTranslation();

    // steps
    const steps = ['Select Campaign and Teams', 'Create Strategy'];

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            _data.filters = filters;
            _data.teams = selectedTeams;
            handleAddEvent(_data);
        } else {
            _data.filters = filters;
            _data.teams = selectedTeams;
            handleEditEvent(_data);
        }
    }

    // data vars
    const [allCampaigns, setAllCampaigns] = React.useState([]);
    const [selectedCampaign, setSelectedCampaign] = React.useState(0);
    const [allTeams, setAllTeams] = React.useState([]);
    const [selectedTeams, setSelectedTeams] = React.useState([]);
    const [otherStrategies, setOtherStrategies] = React.useState('');

    const [allData, setAllData] = useState([]);
    const [filters, setFilters] = useState([{ field1: "", field2: "is", field3: "" },]);
    const [selectedFilters, setSelectedFilters] = React.useState({
        "range": {
            "convTotalDue": {
                "min": null,
                "max": null
            },
        },
        "filter": {
            "bookName": [],
            "region": [],
            "segment": [],
            "subSegment": [],
            "paymentMethod": [],
            "revenueType": []
        }
    });


    //table vars
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [paginationFlag, setPaginationFlag] = useState(false);

    // ui handlers
    const [loading, setLoading] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const fetchCampaigns = () => {
        getAll(moduleCampaigns)
            .then((_res: any) => {
                setAllCampaigns(_res);
            })
            .catch(_err => {
                log(_err);
            })
    }

    const fetchTeams = (_campaignId) => {
        // reset all teams
        setAllTeams([]);

        // get all teams in the location of campaign
        let seletecCampaign = allCampaigns.filter(_campaign => _campaign.id == _campaignId);
        if (seletecCampaign.length > 0) {
            const locationId = seletecCampaign[0].locationId;

            getAll(moduleTeams + '/location/' + locationId)
                .then((_res: any) => {
                    setAllTeams(_res);
                    // assign current teams if edit mode\
                    if (isEditMode) {
                        let teamsArray = [];
                        recordToUpdate.strategyStrategyCampaign.forEach(_record => {
                            if (!teamsArray.includes(_record.teamId)) {
                                teamsArray.push(_record.teamId);
                            }
                        })
                        setSelectedTeams(teamsArray);
                    }
                })
                .catch(_err => {
                    log(_err);
                })
        }
    }

    // Add a new filter row
    const addFilterRow = () => {
        setFilters([...filters, { field1: "", field2: "is", field3: "" }]);
    };

    // Remove a filter row
    const removeFilterRow = (index) => {
        const updatedFilters = filters.filter((_, i) => i !== index);
        setFilters(updatedFilters);
    };

    // Update a specific field in a filter row
    const handleFilterChange = (index, field, value) => {
        const updatedFilters = [...filters];
        updatedFilters[index][field] = value;

        if (field === "field2" && value === "isEmpty") {
            updatedFilters[index].field3 = true; // Automatically set field3 to true
        } else if (field === "field2" && updatedFilters[index].field3 === true) {
            updatedFilters[index].field3 = ""; // Reset field3 if it's not isEmpty
        }

        setFilters(updatedFilters);
    };

    const fetch = () => {
        fetchCampaigns();
    }

    const submitFilter = () => {
        setTotalRowCount(0);
        setAllData([]);
        setTableData([]);
        setLoading(true);

        post(moduleDebtorDetail + '/filter/stragegies', { filters: filters, page: currentPage, size: pageSize, campaignId: selectedCampaign })
            .then(_res => {

                setAllData(_res);

                // set total count
                setTotalRowCount(_res.rows.count);

                const rows: any = [];
                _res.rows.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.accountName, _record?.account_status,
                    // _record?.conv30Days,_record?.conv60Days, _record?.conv90Days, _record?.conv120Days, _record?.conv180Days, _record?.convMoreThan180Days,
                    _record?.msisdn, _record?.msisdn_cnt, _record?.paymentMethod, _record?.mtn_acc_status,
                    _record?.firstName, _record?.surName, _record?.birthDt, _record?.es_rank, _record?.ageDays, _record?.ls_rank,
                    _record?.mtn_status, _record?.date_entered
                    ]))
                });

                setTableData(rows);

                setLoading(false);
            })
            .catch(_err => {
                console.log(_err);
                setLoading(false); // hide loader
            })
    };
    // hooks
    useEffect(() => {
        if (isEditMode) {

            let parsedData = {};
            try {
                parsedData = typeof recordToUpdate?.filters === "string" ? JSON.parse(recordToUpdate.filters) : recordToUpdate?.filters || {};
            } catch (error) {
                log("Error parsing filters:", error);
                parsedData = {};
            }

            reset(recordToUpdate);
            setFilters(parsedData);
            setValue('campaignId', recordToUpdate.strategyStrategyCampaign[0]?.campaignId);
            setSelectedCampaign(recordToUpdate.strategyStrategyCampaign[0]?.campaignId);
            submitFilter();
        }
        fetch();
    }, []);

    // the following two useEffect are to asynchronously set selected campaign and teams in sequence when updating record
    useEffect(() => {
        if (selectedCampaign > 0) {
            // fetch teams available in the location of campaign in edit mode only
            fetchTeams(selectedCampaign);
        }
    }, [selectedCampaign]);

    useEffect(() => {
        if (isEditMode && selectedCampaign > 0) {
            // fetch teams available in the location of campaign in edit mode only
            fetchTeams(selectedCampaign);
        }
    }, [allCampaigns]);

    let columns = [
        { name: "Account Number", options: { filterOptions: { fullWidth: true } } },
        { name: "Account Status", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 1-30", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 31-60", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 61-90", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 91-120", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 121-150", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days 151-180", options: { filterOptions: { fullWidth: true } } },
        // { name: "Days Greater Than 180", options: { filterOptions: { fullWidth: true } } },
        { name: "Msisdn", options: { filterOptions: { fullWidth: true } } },
        { name: "Msisdn Cnt", options: { filterOptions: { fullWidth: true } } },
        { name: "Payment_method", options: { filterOptions: { fullWidth: true } } },
        { name: "Mtn Acc Status", options: { filterOptions: { fullWidth: true } } },
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Sur Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Birth Date", options: { filterOptions: { fullWidth: true } } },
        { name: "ES Rank", options: { filterOptions: { fullWidth: true } } },
        { name: "Age Days", options: { filterOptions: { fullWidth: true } } },
        { name: "Ls Rank", options: { filterOptions: { fullWidth: true } } },
        { name: "MTN Status'n", options: { filterOptions: { fullWidth: true } } },
        { name: "Date Entered", options: { filterOptions: { fullWidth: true } } },

        {
            // name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => { return { align: "right" } },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    // return (
                    //     <div className='d-inline-flex'>
                    //         <div className="px-2">
                    //             <Tooltip title={t('common.view')}>
                    //                 <IconButton aria-label="delete" size="small"
                    //                 // onClick={() => handleToggleViewLeads(allData[rowIndex])}
                    //                 >
                    //                     <FontAwesomeIcon className="edit-btn" icon='eye' />
                    //                 </IconButton>
                    //             </Tooltip>
                    //         </div>
                    //     </div>
                    // )
                    //     ;
                }
            }
        }
    ];

    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: true,
        filter: false,
        filterType: "checkbox",
        responsive: "scroll",
        resizableColumns: false,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 75, 100],
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        tableBodyHeight: '400px',
        selectableRowsHideCheckboxes: false,
        sort: false,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        },
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '90vw', maxHeight: '90vh', overflowY: 'auto' }}>

                {/* Stepper */}
                <div className="p-3">
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>


                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">

                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.strategy')}
                                    </h3>
                                </div>


                                {/* Step 1 */}
                                {activeStep == 0 &&
                                    <div className={'row'}>
                                        {/* Field name */}
                                        <div className="col-md-6">
                                            <FormControl fullWidth>
                                                <TextField id="nameInput"
                                                    {...register("name", { required: true })}
                                                    label={t('strategy.name')}
                                                    type={"text"}
                                                    error={!!errors.name}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field campaign id */}
                                        <div className="col-md-6">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="bookTemplateIdLabel">
                                                    Select Campaigns
                                                </InputLabel>
                                                {allCampaigns.length > 0 &&
                                                    <Select labelId="campaignIdLabel"
                                                        id="campaignIdSelect"
                                                        {...register("campaignId", { required: true })}
                                                        error={!!errors.campaignId}
                                                        value={watch('campaignId')}
                                                        onChange={(event) => {
                                                            setSelectedCampaign(event.target.value);
                                                        }
                                                        }
                                                        label="Select Campaign">
                                                        {
                                                            allCampaigns.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allCampaigns.length == 0 &&
                                                    <>
                                                        <Select
                                                            disabled>
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>

                                        {/* Field team ids */}
                                        <div className="col-md-6 mt-3">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="teamsIdLabel">
                                                    Select Teams
                                                </InputLabel>
                                                {allTeams.length > 0 &&
                                                    <Select labelId="teamsIdLabel"
                                                        id="teamsIdLabel"
                                                        multiple
                                                        onChange={(event) => {
                                                            setSelectedTeams(event.target.value);
                                                        }
                                                        }
                                                        value={selectedTeams}
                                                        label="Select Teams">
                                                        {
                                                            allTeams.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allTeams.length == 0 &&
                                                    <>
                                                        <Select labelId="teamsIdLabel"
                                                            label="Select Teams"
                                                            disabled>
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>

                                        {/* Field priority */}
                                        <div className="col-md-6 mt-3">
                                            <FormControl fullWidth>
                                                <TextField id="priorityInput"
                                                    {...register("priority", { required: true })}
                                                    label={t('strategy.priority')}
                                                    type={"number"}
                                                    error={!!errors.priority}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                    </div>
                                }


                                {/* Step 2 */}
                                {activeStep == 1 &&
                                    <>

                                        <div className={'row'}>
                                            {/* Other strategies for same strategy */}
                                            <div className="col-md-6">
                                                <div className="alert alert-info">
                                                    <h4 className={'fw-bolder'}>
                                                        Total Accounts
                                                    </h4>
                                                    {loading &&
                                                        <>
                                                            <Skeleton />
                                                            <Skeleton />
                                                            <Skeleton />
                                                            <Skeleton />
                                                        </>
                                                    }
                                                    {!loading &&
                                                        <p style={{ whiteSpace: 'pre-line' }}>
                                                            Total {totalRowCount} accounts in {selectedCampaign} comes under this stragey
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'row'}>
                                            {/* Other strategies for same strategy */}
                                            <div className="col-md-12 mt-3">

                                                {/* Filters */}
                                                <div className="card p-3 mb-3 shadow-lg">
                                                    <div className="row">
                                                        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                                                            FILTER
                                                        </Typography>
                                                        {filters.map((filter, index) => (
                                                            <Box
                                                                className="mt-4"
                                                                key={index}
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: 2,
                                                                    mb: 2
                                                                }}
                                                            >
                                                                {/* First Dropdown */}
                                                                <Select
                                                                    value={filter.field1}
                                                                    onChange={(e) => handleFilterChange(index, "field1", e.target.value)}
                                                                    displayEmpty
                                                                    fullWidth
                                                                    size="small"
                                                                >
                                                                    <MenuItem value="" disabled>
                                                                        Select Field
                                                                    </MenuItem>
                                                                    {firstDropdownOptions.map((option) => (
                                                                        <MenuItem key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>

                                                                {/* Second Dropdown */}
                                                                <Select
                                                                    value={filter.field2}
                                                                    onChange={(e) => handleFilterChange(index, "field2", e.target.value)}
                                                                    fullWidth
                                                                    size="small"
                                                                >
                                                                    {secondDropdownOptions.map((option) => (
                                                                        <MenuItem key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>

                                                                {/* Text Input or Read-Only Field */}
                                                                {filter.field2 === "isEmpty" ? (
                                                                    <TextField
                                                                        value="true"
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                        }}
                                                                        fullWidth
                                                                        size="small"
                                                                    />
                                                                ) : (
                                                                    <TextField
                                                                        value={filter.field3}
                                                                        onChange={(e) => handleFilterChange(index, "field3", e.target.value)}
                                                                        placeholder="Enter Value"
                                                                        fullWidth
                                                                        size="small"
                                                                    />
                                                                )}

                                                                {/* Remove Row Button */}
                                                                <IconButton onClick={() => removeFilterRow(index)} color="error">
                                                                    <RemoveCircleOutline />
                                                                </IconButton>
                                                            </Box>
                                                        ))}

                                                        {/* Add Row and Apply Filter Buttons */}
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: 2, // Adds spacing between the buttons
                                                                mt: 2,  // Adds margin-top for some spacing from the above content
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                startIcon={<AddCircleOutline />}
                                                                onClick={addFilterRow}
                                                            >
                                                                Add Row
                                                            </Button>

                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={submitFilter}
                                                            >
                                                                Apply Filter
                                                            </Button>
                                                        </Box>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <MUIDataTable
                                                title={t('common.allRecords')}
                                                data={tableData}
                                                columns={columns}
                                                // @ts-ignore
                                                options={options}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </CardContent>


                    {/* Footer */}
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                                {/* Cancel Button */}
                                {activeStep === 0 &&
                                    <Button variant="outlined"
                                        onClick={onCancel}>
                                        {t('common.cancel')}
                                    </Button>
                                }

                                {/* Back Button */}
                                {activeStep !== 0 &&
                                    <Button variant="outlined"
                                        onClick={handleBackStep}>
                                        {t('common.back')}
                                    </Button>
                                }

                                <Box sx={{ flex: '1 1 auto' }} />

                                {/* Next Button */}
                                {activeStep !== steps.length - 1 &&
                                    <LoadingButton onClick={handleNextStep}
                                        variant="contained"
                                        className="bg-custom-gradient">
                                        Next
                                    </LoadingButton>
                                }

                                {/* Okay Button */}
                                {activeStep === steps.length - 1 &&
                                    <LoadingButton loading={isLoading}
                                        type="submit"
                                        variant="contained"
                                        className="bg-custom-gradient">
                                        {isEditMode ? t('common.edit') : t('common.add')}
                                    </LoadingButton>
                                }
                            </Box>
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default StrategyForm;
